import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Media } from "@ryerson/frontend.layout";
import { useApplication } from "@ryerson/frontend.application";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Typography } from "@ryerson/frontend.typography";
import { Container, ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Icon } from "@ryerson/frontend.assets";
import { NewsEventsStaticContent } from "./Shared";
import { Button } from "@ryerson/frontend.button";
import { ButtonLink, Link } from "@ryerson/frontend.navigation";

export type EventsLanderProps = {
	content: NewsEventsStaticContent;
};

export type EventsCategory = {
	label: string;
	quarter: string;
	possibleMonths: string[];
};
export type ContentfulEvent = {
	slug: string;
	title: string;
	eventDates: string;
	eventLocation: string;
	date: string;
	node_locale: "en-US" | "fr-CA" | "es-MX";
	contentful_id: string;
	heroImage: { file: { url: string } };
};

interface MobileCardProps {
	contents: any;
	index: number;
	events: any;
}

const Title = styled(Typography)`
	font-size: 37.5px;
	line-height: 45px;
`;

const DatesCategory = styled.div`
	text-align: left;
	min-height: 58px;
	height: auto;
	line-height: 58px;
	display: block;
	width: 100%;
`;
const NavContainer = styled.div`
	display: block;
	position: relative;
	width: 100%;
	height: 58px;
	box-sizing: border-box;
	margin-top: 5px;
	margin-bottom: 39px;
`;

const NavItem = styled.div`
	display: inline-block;
	height: 58px;
	line-height: 57px;
	cursor: pointer;
	width: auto;
`;
const EventCard = styled.div`
	height: 320px;
	max-width: 535px;
	margin-bottom: 10px;
`;
const CardLeft = styled(FlexItem)`
	max-width: 335px;
	width: 335px;
	height: 320px;
	${(props: any) => {
		const { theme } = props;
		return css`
			background: ${theme.colors.primary.darkerGray};
		`;
	}}
`;
const Contents = styled(Container)`
	padding-left: 45px;
	padding-right: 75px;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.darkerGray};
		`;
	}}
`;
const EventSubInfo = styled.div``;
const EventTitle = styled(Typography)`
	font-size: 20px;
	line-height: 25px;
	width: 90%;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				padding: 28px 0;
			}
		`;
	}}
`;

const CardRight = styled(FlexItem)`
	max-width: 200px;
	height: 320px;
`;
const CardImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const MobileFilter = styled.div`
	padding-bottom: 29px;
`;
const MobileCardSection = styled.div`
	width: 100%;
	display: block;
	padding-bottom: 45px;
	position: relative;
	margin-top: 60px;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.darkerGray};
		`;
	}}
`;

const MobileCardImage = styled.div`
	width: calc(100% - 20px);
	position: relative;
	top: -40px;
	margin-bottom: -40px;
`;
const Divider = styled.hr`
	display: block;
	width: 100%;
	height: 1px;
	margin-top: 0;
	opacity: 0.1;
	position: absolute;
	bottom: -9px;
	left: 0px;
`;
const MobileCardText = styled.div`
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	box-sizing: border-box;
`;

const RevealBox = styled.div`
	width: calc(100% - 40px);
	position: absolute;
	top: 60px;
	left: 20px;
	height: 0px;
	overflow: hidden;
	padding: 0px 20px;
	box-sizing: border-box;
	box-shadow: 0px 10px 80px rgb(0 0 0 / 7%), 0px 10px 17.869px rgb(0 0 0 / 4%),
		0px 5px 5.32008px rgb(0 0 0 / 3%);
	background-color: #ffffff;
	z-index: 1;
`;

const MobileCardContainer = styled.div`
	min-height: 200px;
`;

const MobileDivider = styled.hr`
	display: block;
	width: 100%;
	opacity: 0.1;
`;

const CategorySelector = styled.span`
	display: inline-block;
`;

const MoreEventsButton = styled.div`
	padding: 43px 64px 0 64px;
`;

const Spacer = styled.div`
	height: 43px;
`;

const EventsLander: React.FC<EventsLanderProps> = ({ content }) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	const queryData = useStaticQuery(graphql`
		query AllMediaForNewsQuery {
			allContentfulEvent(sort: { order: DESC, fields: contentful_id }) {
				nodes {
					slug
					title
					eventDates
					eventLocation
					date
					node_locale
					contentful_id
					heroImage {
						file {
							url
						}
					}
				}
			}
		}
	`);

	let upcomingEvents: any = [];
	let allEvents: any = [];

	queryData?.allContentfulEvent?.nodes?.forEach((node: any) => {
		if (node.node_locale.split("-").shift() === language) {
			allEvents.push(node);
			const now = new Date();
			const today = now.toISOString().split("T")[0];
			if (node.date > today) {
				// selected date is not the past
				upcomingEvents.push(node);
			}
		}
	});

	upcomingEvents.sort(function (a: any, b: any) {
		return new Date(a.date).valueOf() - new Date(b.date).valueOf();
	});
	allEvents.sort(function (a: any, b: any) {
		return new Date(a.date).valueOf() - new Date(b.date).valueOf();
	});
	function grabMonthFromDate(date: string) {
		const dates = date.split("-");
		return dates[1];
	}

	const [selectedEvents, setSelectedEvents] = React.useState(upcomingEvents);
	const [quarter, setQuater] = React.useState<string>("");
	const [filter, setFilter] = React.useState<string[]>([""]);
	const [mobileShown, setMobileShown] = React.useState<number>(6);
	const [showMobileNav, setShowMobileNav] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (quarter === "") {
			if (content.minimal) {
				let minimalEvents = [upcomingEvents[0], upcomingEvents[1]];
				setSelectedEvents(minimalEvents);
			} else {
				setSelectedEvents(upcomingEvents);
			}
		} else {
			let newCurrent: any[] = [];
			allEvents.forEach((item: any, index: number) => {
				if (filter.includes(grabMonthFromDate(item.date))) {
					const now = new Date();
					const today = now.toISOString().split("T")[0];
					if (item.date > today) {
						newCurrent.push(item);
					}
				}
			});
			setSelectedEvents(newCurrent);
		}
		if (content.minimal) {
			setMobileShown(2);
		} else {
			setMobileShown(6);
		}
	}, [filter]);
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<Title
					variant="h4"
					weight="bold"
					css={css`
						margin-bottom: ${content.minimal ? "53px" : "0px"};
					`}
				>
					{content.upcomingEventsLabel}
				</Title>

				{upcomingEvents.length === 0 ? (
					<>
						<Typography variant="p">There are no upcoming events.</Typography>
					</>
				) : (
					<>
						{content.minimal ? (
							<></>
						) : (
							<>
								<DatesCategory>
									<NavContainer>
										{content.eventsCategories.map(
											(category: EventsCategory, index: number) => {
												return (
													<NavItem
														key={index}
														css={css`
															margin-right: ${index ===
															content.eventsCategories.length - 1
																? "0px"
																: "40px"};
															border-bottom: ${quarter ===
															category.quarter
																? "1px solid " +
																  theme.colors.primary.link
																: "1px solid transparent"};
														`}
														onClick={() => {
															setQuater(category.quarter);
															setFilter(category.possibleMonths);
														}}
													>
														<Typography
															variant="span"
															type="secondary"
															color={theme.colors.secondary.header}
															size="md"
															weight="bold"
															css={css`
																display: block;
																height: 57px;
																line-height: 57px;
															`}
														>
															{category.label}
														</Typography>
													</NavItem>
												);
											}
										)}
										<Divider />
									</NavContainer>
								</DatesCategory>
							</>
						)}

						<div
							css={css`
								max-height: ${content.minimal ? "654px" : "1974px"};
								overflow-x: ${content.minimal ? "hidden" : "auto"};
								overflow-y: ${content.minimal ? "hidden" : "auto"};
							`}
						>
							{/*Because sometimes graphql returns a null value even when there's an
							event, we're going to ensure all the values that are required exist
							before displaying an event card. NEXTJS WILL FIX THIS*/}
							{selectedEvents.map(
								(event: any, index: number) =>
									event &&
									event.eventDates &&
									event.date &&
									event.eventLocation &&
									event.heroImage &&
									event.heroImage.file &&
									event.slug &&
									event.title && (
										<Link to={"/about-us/news-events/" + event?.slug}>
											<EventCard key={index}>
												<Flex>
													<CardLeft theme={theme}>
														<Contents
															vPadding="35px"
															hPadding="45px"
															theme={theme}
														>
															<Flex
																direction="column"
																justifyContent="space-between"
																css={css`
																	min-height: 250px;
																`}
															>
																<FlexItem>
																	<EventTitle
																		theme={theme}
																		variant="h5"
																		color={
																			theme.colors.primary
																				.white
																		}
																	>
																		{event?.title}
																	</EventTitle>
																</FlexItem>
																<FlexItem>
																	<EventSubInfo>
																		<div>
																			<Typography
																				variant="p"
																				color={
																					theme.colors
																						.primary
																						.lightGray
																				}
																			>
																				{event.eventDates}
																			</Typography>
																		</div>
																		<div>
																			<Typography
																				variant="p"
																				color={
																					theme.colors
																						.primary
																						.lightGray
																				}
																			>
																				{
																					event.eventLocation
																				}
																			</Typography>
																		</div>
																	</EventSubInfo>
																</FlexItem>
															</Flex>
														</Contents>
													</CardLeft>
													<CardRight>
														<CardImage
															src={
																event.heroImage?.file
																	? event.heroImage?.file?.url +
																	  "?fm=jpg&fit=fill&w=200&h=320"
																	: ""
															}
														/>
													</CardRight>
												</Flex>
											</EventCard>
										</Link>
									)
							)}
						</div>
						{content.minimal ? (
							<>
								<Spacer />
								<ButtonLink
									label={content.viewAllEventsLabel}
									to={content.viewAllEventsURL}
									linkType="internal"
									type="secondary"
									size="sm"
								/>
							</>
						) : (
							<></>
						)}
					</>
				)}
			</Media>

			<Media lessThan="lg">
				<ContentSection vPadding="29px" hPadding="0">
					{upcomingEvents.length === 0 ? (
						<>
							<Typography variant="p">There are no upcoming events.</Typography>
						</>
					) : (
						<>
							{content.minimal ? (
								<></>
							) : (
								<MobileFilter>
									<label htmlFor="period">
										<Typography color={theme.colors.primary.gray}>
											{content.filterLabel}
										</Typography>
										<Typography
											variant="span"
											size="lg"
											color={theme.colors.primary.link}
										>
											{content.eventsCategories.map(
												(category: EventsCategory, index: number) => {
													return (
														<React.Fragment key={index}>
															{quarter === category.quarter ? (
																<CategorySelector
																	onClick={() => {
																		setShowMobileNav(
																			showMobileNav
																				? false
																				: true
																		);
																	}}
																>
																	{category.label}
																	<Icon
																		icon="arrow-down"
																		css={css`
																			cursor: pointer;
																			margin-left: 8px;
																		`}
																		size="xs"
																		color={
																			theme.colors.primary
																				.link
																		}
																	/>
																</CategorySelector>
															) : (
																<></>
															)}
														</React.Fragment>
													);
												}
											)}
										</Typography>
										<RevealBox
											css={css`
												background-color: ${theme.colors.primary
													.background};
												height: ${showMobileNav === true ? "230px" : "0px"};
												transition: height 0.25s ease;
											`}
										>
											{content.eventsCategories.map(
												(category: EventsCategory, index: number) => {
													return (
														<React.Fragment key={index}>
															<Typography
																variant="div"
																color={
																	quarter === category.quarter
																		? theme.colors.primary.gray
																		: theme.colors.primary
																				.header
																}
																size="md"
																weight="bold"
																css={css`
																	display: block;
																	height: 20px;
																	line-height: 20px;
																	margin-top: ${index < 1
																		? "13px"
																		: "0px"};
																	margin-bottom: 10px;
																`}
															>
																<CategorySelector
																	onClick={() => {
																		setQuater(category.quarter);
																		setFilter(
																			category.possibleMonths
																		);
																		setShowMobileNav(
																			showMobileNav
																				? false
																				: true
																		);
																	}}
																>
																	{category.label}
																</CategorySelector>
															</Typography>
															{index ===
															content.eventsCategories.length - 1 ? (
																<></>
															) : (
																<MobileDivider
																	css={css`
																		color: ${theme.colors
																			.primary.darkerGray};
																		margin-bottom: 10px;
																	`}
																/>
															)}
														</React.Fragment>
													);
												}
											)}
										</RevealBox>
									</label>
								</MobileFilter>
							)}
							<MobileCardContainer>
								<ContentSection vPadding="0" hPadding="0">
									{selectedEvents.map((event: ContentfulEvent, index: number) => {
										return index < mobileShown ? (
											<MobileCard
												key={index}
												contents={event}
												index={index}
												events={selectedEvents}
											/>
										) : (
											<></>
										);
									})}
								</ContentSection>
								{selectedEvents.length > mobileShown && !content.minimal ? (
									<MoreEventsButton>
										<Button
											label={content.loadMoreButton}
											shape="rounded"
											variant="outline"
											fullwidth={true}
											background={theme.colors.primary.secondaryBrand}
											onClick={() => {
												setMobileShown(mobileShown + 6);
											}}
										></Button>
									</MoreEventsButton>
								) : content.minimal ? (
									<>
										<ButtonLink
											label={content.viewAllEventsLabel}
											to={content.viewAllEventsURL}
											linkType="internal"
											type="primary"
										/>
									</>
								) : (
									<></>
								)}
							</MobileCardContainer>
						</>
					)}
				</ContentSection>
			</Media>
		</>
	);
};

export default EventsLander;

const MobileCard: React.FC<MobileCardProps> = ({ contents, index, events }) => {
	const { theme } = useTheme();
	return (
		<Link to={"/about-us/news-events/" + contents?.slug}>
			<MobileCardSection
				theme={theme}
				css={css`
					margin-bottom: ${contents && index === events.length - 1 ? "40px" : "0px"};
				`}
			>
				<MobileCardImage>
					<img
						css={css`
							width: 100%;
							height: auto;
							display: block;
							margin-left: ${index % 2 === 0 ? "0px" : "20px"};
						`}
						src={
							contents.heroImage?.file
								? contents.heroImage?.file?.url + "?fm=jpg&fit=fill&w=660&h=440"
								: ""
						}
					/>
				</MobileCardImage>
				<MobileCardText>
					<EventTitle theme={theme} variant="h5" color={theme.colors.primary.white}>
						{contents?.title}
					</EventTitle>
					<Divider css={{ color: theme.colors.primary.white }} />

					<Typography variant="p" color={theme.colors.primary.lightGray}>
						{contents?.eventDates}
					</Typography>
					<Typography variant="p" color={theme.colors.primary.lightGray}>
						{contents?.eventLocation}
					</Typography>
				</MobileCardText>
			</MobileCardSection>
		</Link>
	);
};
